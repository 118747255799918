<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Projects
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Some words not found what we want. FixIt!
                </span>
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.term="{ item }"> </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search in terms"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <b-dropdown text="Actions">
                        <b-dropdown-item @click.prevent="goToItem(item)">
                          Change project
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="editItem(item)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="deleteItem(item)">
                          Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Reset
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import {
  CHANGE_SALES_CHANNEL,
  DELETE_SALES_CHANNEL,
  PULL_SALES_CHANNELS
} from "@/core/services/store/project.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import { PULL_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  data() {
    return {
      search: this.$route.query.name,
      options: {},
      headers: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    onDateRangeChanged() {
      this.reloadData();
    }
  },
  methods: {
    goToItem(item) {
      const selectId = item.id;
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // dummy delay
      event.currentTarget.getAttribute("data-id");
      setTimeout(() => {
        // send register request
        this.$store.dispatch(CHANGE_SALES_CHANNEL, selectId);
        this.$store.dispatch(PULL_PERSONAL_INFO);
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    },
    editItem(item) {
      this.$router.push({ name: "project-edit", query: { id: item.id } });
    },
    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want delete \`${item.name}\`. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(DELETE_SALES_CHANNEL, item.id).then(() => {
            this.reloadData();
            Swal.fire("Deleted!", "", "success");
          });
        }
      });
    },
    reloadData() {
      this.$store.dispatch(PULL_SALES_CHANNELS);
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    onDateRangeChanged() {
      return this.dateRange;
    },
    ...mapState({
      errors: state => state.project.errors,
      message: state => state.project.message,
      hasMessage: state => state.project.hasMessage,
      items: state => state.project.projects,
      loading: state => state.project.loading
    }),
    ...mapGetters(["currentProject", "dateRange"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Fixit" }]);
  }
};
</script>
